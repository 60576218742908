<template>
  <div>
    <catalog-side-nav></catalog-side-nav>
    <catalog-mobile-banner></catalog-mobile-banner>
    <div :class="{'download-bar': showMobileDownloadBanner}">
      <app-page class="catalog" :class="{'home-catalog': isInHomeScreen}">
        <catalog-nav v-if="init"></catalog-nav>
        <template v-if="isInHomeScreen">
          <catalog-new-merchant></catalog-new-merchant>
          <!-- <catalog-home-category></catalog-home-category> -->
          <catalog-footer></catalog-footer>
        </template>
        <template v-else>
          <catalog-filter></catalog-filter>
          <div class="catalog-page">
            <catalog-home-category-slider></catalog-home-category-slider>
            <catalog-merchant-list></catalog-merchant-list>
            <catalog-footer></catalog-footer>
            <catalog-back-to-top></catalog-back-to-top>
          </div>
          <catalog-filter-modal></catalog-filter-modal>
          <catalog-search-modal></catalog-search-modal>
        </template>
      </app-page>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import '../scss/app_mobile.scss';

import Page from "@/mobileComponents/Page";
import Nav from "@/mobileComponents/Catalog/Nav";
import NewMerchant from "@/mobileComponents/Catalog/NewMerchant";
import HomeCategory from "@/mobileComponents/Catalog/HomeCategory";
import Filter from "@/mobileComponents/Catalog/FilterNav";
import MerchantList from "@/mobileComponents/Catalog/MerchantList";
import FilterModal from "@/mobileComponents/Catalog/FilterModal";
import Search from "@/mobileComponents/Catalog/Search";
import Footer from "@/mobileComponents/Catalog/Footer";
import SideNav from "@/mobileComponents/Catalog/SideNav";
import BackToTop from "@/mobileComponents/Catalog/BackToTop";
import MobileBanner from "@/mobileComponents/MobileBanner";
import HomeCategorySlider from "@/mobileComponents/Catalog/HomeCategorySlider";

export default {
  components: {
    'app-page': Page,
    'catalog-nav': Nav,
    'catalog-new-merchant': NewMerchant,
    'catalog-home-category': HomeCategory,
    'catalog-filter': Filter,
    'catalog-merchant-list': MerchantList,
    'catalog-filter-modal': FilterModal,
    'catalog-search-modal': Search,
    'catalog-footer': Footer,
    'catalog-back-to-top': BackToTop,
    'catalog-mobile-banner': MobileBanner,
    'catalog-side-nav': SideNav,
    'catalog-home-category-slider': HomeCategorySlider
  },
  data(){
    return {
      init: false
    }
  },
  methods: {
    ...mapActions(['getHomeAction', 'initCatalogFilterAction', 'copyAddressByTokenAction', 'getFilteredMerchantsCatalogAction', 'updateFilter', 'setCatalogAddressAction', 'setSubCategoriesAction']),
    scrollManager(){
      if(localStorage.getItem('last_page') === 'merchant'){
        if(localStorage.getItem('catalog_scroll')){
          document.querySelector('.catalog').scrollTop = localStorage.getItem('catalog_scroll')
        }
        document.querySelector('.catalog').addEventListener('scroll', (e) => {
          localStorage.setItem('catalog_scroll', e.target.scrollTop);
        })
      }
      localStorage.setItem('last_page', 'home');
    },
    setDefaultFavicon(){
      document.getElementById('ico').href = '/favicon.ico';
      document.getElementById('i180').href = '/apple-touch-icon.png';
      document.getElementById('i32').href = '/icon-32.png';
      document.getElementById('i16').href = '/icon-16.png';
    }
  },
  computed: {
    ...mapGetters(['isInHomeScreen', 'showMobileDownloadBanner', 'filteredMerchants'])
  },
  async mounted() {

    if(this.$route.query.category_id){
      this.updateFilter({subCategories:[parseInt(this.$route.query.category_id)]});
    }
    if(this.$route.query.lat && this.$route.query.lng && this.$route.query.addressName){
      await this.setCatalogAddressAction( {address: this.$route.query.addressName, city: null, zip: null, country: null, lat:this.$route.query.lat, lng:this.$route.query.lng, precise: false, reload: false})
    }
    else if(this.$route.query.address_token){
      await this.copyAddressByTokenAction({token: this.$route.query.address_token})
    }
    if(this.$route.query){
      //this.$router.push('/');
    }
    await this.initCatalogFilterAction();


    this.init = true;
    if(!this.isInHomeScreen){
      if(this.filteredMerchants.length === 0){
        await this.getFilteredMerchantsCatalogAction();
      }
      this.scrollManager();
    }
    else{
      this.getHomeAction();
    }
    window.scrollTo(0,1);

    this.setDefaultFavicon();
  }
}
</script>
