<template>
  <div class="merchant-list">


    <div class="merchant-list__loading" v-if="catalogMerchantLoading">
      <div class="item" v-for="i in [1,2,3]">
        <div class="item-image-loading"></div>
      </div>
    </div>

    <div class="merchant-list__title mt-1" v-if="availableDelivery.length > 0 && filter.category === 'all' && !currentCategory && !filter.search && (filteredMerchantsUnavailable.length > 0 || filteredMerchantsAvailable.length > 0)">
      <span>A proximité</span>
    </div>


    <div class="merchant-list__title pl-0 mb-1 mt-1 d-flex align-items-center" v-if="filter.category === 'all' && currentCategory && !filter.search && (filteredMerchantsUnavailable.length > 0 || filteredMerchantsAvailable.length > 0)">
      <button class="category-list-button-back mr-3" @click="resetCategory()"  v-if="!$route.query.hide_back_button">
        <i class="material-icons notranslate">arrow_back</i>
        Retour
      </button>

      <span>{{currentCategory.name}}</span>
    </div>

    <template v-if="filter.type === 'delivery'">
      <merchant-item v-for="merchant in availableDelivery" :key="merchant.id" :merchant="merchant" v-if="!catalogMerchantLoading"></merchant-item>
      <div class="merchant-list__title mb-3" v-if="availablePickupOnly.length > 0">
        <span>À emporter uniquement</span>
      </div>
      <merchant-item v-for="merchant in availablePickupOnly" :key="merchant.id" :merchant="merchant" v-if="!catalogMerchantLoading"></merchant-item>
    </template>
    <template v-else>
      <merchant-item v-for="merchant in filteredMerchantsAvailable" :key="merchant.id" :merchant="merchant" v-if="!catalogMerchantLoading"></merchant-item>
    </template>



    <template v-if="filteredMerchantsUnavailable.length !== 0">
      <div class="merchant-list__title">
        <span>Ouvre bientôt</span>
      </div>
      <merchant-item v-for="merchant in filteredMerchantsUnavailable" :key="merchant.id" :merchant="merchant" v-if="!catalogMerchantLoading"></merchant-item>
    </template>



    <div class="merchant-list__error" v-if="catalogData && !catalogData.inZone">
      <h2>Delicity n'est pas encore dans votre ville...</h2>
      <span>Promis on essaye de faire vite ! 🚴‍♂️</span>
    </div>
    <div class="merchant-list__error" v-else-if="catalogData && catalogData.inZone && filteredMerchantsAvailable.length === 0 && filteredMerchantsUnavailable.length === 0">
      <h2>Nous n'avons rien trouvé...</h2>
      <button class="btn btn-catalog" @click="resetSearch">Voir tous les restaurants</button>
    </div>

  </div>
</template>

<style lang="scss">


.category-list-button-back
{
  background-color: #00ace9;
  color: #fff;
  border: 0;
  padding: 5px 15px;
  padding-left: 10px;
  font-weight: 600;
  font-size: 0.8em;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  i
  {
    margin-right: 5px;
    font-size: 1.4em;
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import MerchantItem from "@/mobileComponents/Catalog/MerchantItem";

export default {
  data() {
    return {
    }
  },
  components: {MerchantItem},
  methods: {
    ...mapActions(['setSubCategoriesAction', 'setSearchAction', 'resetFiltersAction']),
    removeSubCategoryFilter(id){
      const cats = this.filter.subCategories;
      cats.splice(this.filter.subCategories.indexOf(id), 1);

      this.setSubCategoriesAction(cats);
    },
    removeSearch(){
      this.setSearchAction({search: '', change: false});
    },
    resetSearch(){
      this.resetFiltersAction()
    },
    resetCategory(){
      this.setSubCategoriesAction([]);
      if(this.$route.query.rel && this.$route.query.rel === 'fromCategoryPage'){
        this.$router.push('/categories')
      }
    },
  },
  computed: {
    ...mapGetters(['filteredMerchantsAvailable', 'filteredMerchantsUnavailable', 'catalogMerchantLoading', 'catalogData', 'filter', 'subCategories']),
    currentCategory(){
      if(this.filter.subCategories.length > 0){
        return this.subCategories.find(c => c.id === this.filter.subCategories[0])
      }
      return null;
    },

    availableDelivery(){
      return this.filteredMerchantsAvailable.filter(e => e.openInDelivery === true);
    },
    availablePickupOnly(){
      return this.filteredMerchantsAvailable.filter(e => e.openInDelivery === false && e.openInPickup === true);
    },

    selectedSubCategories(){
      const selected = [];
      this.filter.subCategories.forEach((sb) => {
        let find = this.subCategories.find(e => sb === e.id);
        if(find !== -1){
          selected.push(find);
        }
      });
      return selected;
    }
  }
}
</script>
