<template>
  <div class="search-modal" v-if="isOpen">
    <div class="search-modal__search">
      <i class="material-icons notranslate icon">search</i>
      <input type="text" class="form-control border-0" v-model="search"  @input="evt => search = evt.target.value" placeholder="Nom ou type de cuisine...">
      <button @click="$router.replace('')"><i class="material-icons notranslate">close</i></button>
    </div>
    <div class="search-modal__list">



      <div class="list__no-results" v-if="results.length === 0 && categoryResults.length === 0 && search !== ''">
        <img src="https://delicity.b-cdn.net/public/assets/catalog/no_results.gif">
        <strong>Pas de résultats pour votre recherche « {{search}} » 😪</strong>
      </div>


      <div class="list__category-item" @click="setCategory(cat)" v-for="cat in categoryResults" v-if="categoryResults.length !== 0">
        <i class="material-icons notranslate">tune</i>
        <span>Voir la catégorie "{{cat.name}}"</span>
      </div>
      <div class="list__category-separator" v-if="categoryResults.length !== 0"></div>


      <a class="list__item" :href="'/' + result.slug" v-for="(result, i) in results" v-if="i < 5">
        <div class="item__image">
          <img :src="result.logo.path">
        </div>
        <div class="item__details">
          <strong class="title">{{result.name}}</strong>
          <span class="address"><i class="material-icons notranslate-outlined">place</i>{{result.address.address}}</span>
          <div class="meta">
            <span>{{result.meta}}</span>
            <span v-if="result.available" class="availability text-success">Ouvert</span>
            <span v-if="!result.available" class="availability text-danger">Ouvre {{result.nextOpen}}</span>
          </div>
        </div>
      </a>

        <div class="list__see_more_button mb-5" v-if="results.length > 5">
          <button @click="filterSearch" class="btn btn-primary">Voir tous les résultats ({{results.length}})</button>
        </div>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'

export default {
  data() {
    return {
      search: '',
      isOpen: false,
    }
  },
  methods: {
    ...mapActions(['setSearchAction', 'setSubCategoriesAction']),
    close(manual){
      this.search = '';
      this.isOpen = false;
    },
    open(){
      this.search = '';
      this.isOpen = true;
      if(this.$route.hash !== '#search'){
        this.$router.push('#search');
      }
    },
    filterSearch(){
      this.setSearchAction({search: this.search, change: true});
      this.$router.replace('');
    },
    listen(){
      this.$root.$on('openSearch', () => {
        this.open();
      })
    },
    setCategory(cat){
      this.setSubCategoriesAction([cat.id]);
      this.$router.replace('');
    }
  },
  computed: {
    ...mapGetters(['filteredMerchants', 'subCategories']),
    results(){
      let merchants = [];
      if(this.search !== ''){
        const fuse = new Fuse(this.filteredMerchants, {
          keys: ['searchable', 'description', 'keywords', 'name'],
          includeScore: true,
          distance: 100,
          threshold: 0.4,
          shouldSort: true
        })
        merchants = fuse.search(this.search).map(r => r.item);
      }
      return merchants;
    },

    categoryResults(){
      let categories = [];
      if(this.search !== '' && this.search.length >= 3){
        const fuse = new Fuse(this.subCategories, {
          keys: ['name'],
          includeScore: true,
          distance: 100,
          threshold: 0.4,
          shouldSort: true
        })
        categories = fuse.search(this.search).map(r => r.item);
      }
      categories = categories.splice(0,3)
      return categories;
    }
  },
  watch: {
    $route (to, from){
      if(from.hash === '#search' && to.hash === ''){
        this.close();
      }
    }
  },
  mounted() {
    this.listen();
  }
}
</script>
