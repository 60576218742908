<template>
  <div v-show="showCategories.length > 3 && filter.category === 'all' && !currentCategory && !filter.search && (filteredMerchantsUnavailable.length > 0 || filteredMerchantsAvailable.length > 0)">
    <div class="catalog-title pt-3 d-flex justify-content-between align-items-center">
      <span>Explorer</span>
      <button class="category-list-button" @click="$router.push('/categories')">Voir tout</button>
    </div>
    <swiper class="category-list" :options="swiperOptions">
      <swiper-slide
          v-if="c.image"
          v-for="(c,i) in showCategories"
          :key="i">
        <div class="category-item" @click="selectCategory(c)" :style="'background-image: url('+c.image+')'">
          <div class="filter">
            <strong>{{c.name}}</strong>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style lang="scss">

.category-list-button
{
  background-color: #00ace9;
  color: #fff;
  border: 0;
  padding: 3px 15px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.75em;
}

.category-list
{
  width: 100%;
  height: 130px;
  .category-item
  {
    height: 130px;
    width: 130px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    .filter
    {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0,0,0,0.0) 60%, rgba(0,0,0,0.7));
      padding: 5px 10px;
      line-height: 1;
      strong
      {
        color: #fff;
        font-size: 0.8em;
        text-transform: uppercase;
      }
    }
  }
}

</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {Swiper, SwiperSlide},
  data() {
    return {
      showCategories: [],
      swiperOptions: {
        spaceBetween: 15,
        width: 130,
        slidesOffsetBefore: 10,
        slidesOffsetAfter: 10
      }
    }
  },
  methods: {
    ...mapActions(['setSubCategoriesAction', 'setSubCategoriesAction', 'getCategoriesSlider']),

    selectCategory(cat){
      this.setSubCategoriesAction([cat.id]);
    },
    resetCategory(){
      this.setSubCategoriesAction([]);
    },
    async getFeaturedSubCategories(){
      this.showCategories = await this.getCategoriesSlider();
    },
  },
  computed: {
    ...mapGetters(['filteredMerchants', 'filteredMerchantsUnavailable', 'filteredMerchantsAvailable', 'catalogMerchantLoading', 'catalogData', 'filter', 'subCategories']),
    currentCategory(){
      if(this.filter.subCategories.length > 0){
        return this.subCategories.find(c => c.id === this.filter.subCategories[0])
      }
      return null;
    },
  },
  watch: {
    filteredMerchants(val, old){
      if(old.length === 0 && val){
        this.getFeaturedSubCategories();
      }
    }
  },
  mounted() {
    this.getFeaturedSubCategories();
  }
}
</script>
