<template>
  <div class="filter-modal" v-show="isOpen">

    <div class="catalog-modal__heading">
      <div class="title">Modifier votre recherche</div>
      <button @click="$router.replace('')"><i class="material-icons notranslate">close</i></button>
    </div>

    <div class="filter-modal__content filter-modal__content-shadow" ref="filterModalContent">
      <div class="address-form-group" :class="{'address-form-group-error': error === 'address_no_street_number' || error === 'address_no_address'}">

        <div class="group__head">
          <label>Votre adresse</label>
          <a href="#" @click.prevent="currentPosition" v-if="!locationGeocodeError && !locationGeocodeSuccess">
            <i class="material-icons notranslate-outlined">near_me</i>Utiliser ma position
          </a>
          <small class="text-danger text-right" v-if="locationGeocodeError">
            {{locationGeocodeError}}
            <a href="#" @click.prevent="currentPosition">
              Réessayer
            </a>
          </small>
        </div>

        <div class="group__form">
            <vue-google-autocomplete
                id="filterModalAutoComplete"
                classname="form-control autocomplete-address"
                :placeholder="$t('enter_your_address')"
                country="fr"
                ref="catalogAutocomplete"
                v-on:placechanged="selectAddress">
            </vue-google-autocomplete>
          <button class="reset" @click="resetAddress"><i class="material-icons notranslate">close</i></button>
        </div>

        <small class="error-message" v-if="error === 'address_no_street_number'">Vous n'avez pas indiqué le numero de la rue</small>
        <small class="error-message" v-if="error === 'address_no_address'">Adresse introuvable...</small>
      </div>

      <hr>

      <div class="schedule-form-group form-group">
        <label>Récupération</label>
        <div class="radio-group">
          <ui-radio class="item" true-value="delivery" v-model="type">
            Livraison
          </ui-radio>
          <ui-radio class="item" true-value="pickup" v-model="type">
            À emporter
          </ui-radio>
        </div>
      </div>

      <hr>

      <div class="schedule-form-group schedule-form-group__vertical form-group">
        <label>Date & créneau</label>
        <div class="radio-group">
          <ui-radio class="item" true-value="now" v-model="when">
            Maintenant
          </ui-radio>
          <ui-radio class="item" true-value="schedule_for_later" v-model="when">
            Planifier pour plus tard
          </ui-radio>
        </div>
      </div>

      <div class="schedule-form-group-select form-group">
        <div class="select-group">
          <select class="form-select" :disabled="when !== 'schedule_for_later'" v-model="date">
            <option :value="d.value" v-for="d in catalogDateSlots">{{d.label}}</option>
          </select>
          <select class="form-select" :class="{'form-select-invalid': this.errors.find(e => e === 'hours')}" :disabled="when !== 'schedule_for_later'" v-model="hours">
            <option :value="null">Choisir un créneau</option>
            <option :value="h.value" v-for="h in catalogTimeSlots">{{h.label}}</option>
          </select>
        </div>
      </div>


    </div>
    <div class="filter-modal__action" :class="{'filter-modal__action-shadow': scrollShadow}">
      <button class="btn btn-primary" @click="save">Rechercher</button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete'


export default {
  data() {
    return {
      isOpen: false,
      locationLoading: false,
      locationGeocodeError: false,
      locationGeocodeSuccess: false,
      dateSlots: [],
      error: false,
      hourSlots: [],
      type: 'delivery',
      when: 'now',
      date: null,
      hours: null,
      selectedSubCategories: [],
      errors: [],
      other: {
        freeDelivery: false
      },
      scrollShadow: false
    }
  },
  components:{
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  methods: {
    ...mapActions(['setCatalogAddressAction', 'parseCatalogAddressAction', 'setFiltersAction', 'getUserCurrentPositionAction', 'getTimeSlotAction', 'getDateSlotAction']),
    async selectAddress(e){ // Add address by autocomplete
      this.locationLoading = true;
      this.autocompleteData = true;

      if(!e.street_number){
        this.error = 'address_no_street_number';
        this.locationLoading = false;
        return;
      }

      await this.setCatalogAddressAction({address: e.street_number + ' ' + e.route, city: e.locality, zip: e.postal_code, country: e.country, lat:e.latitude, lng:e.longitude, reload: false});
      this.$root.$emit('closeFilterAddress');
      this.locationLoading = false;
    },
    resetSubCategories(){
      this.selectedSubCategories = [];
    },
    toogleSubCategory(id){
      if(this.selectedSubCategories.find(e => e === id)){
        this.selectedSubCategories.splice(this.selectedSubCategories.indexOf(id), 1);
      }
      else{
        this.selectedSubCategories.push(id)
      }
    },
    parseAddress(){ // Add address by address value
      const address = document.getElementById('catalogAutocomplete').value;
      if(address === '' || address === null){
        this.error = 'address_no_address';
        return;
      }

      this.locationLoading = true;
      setTimeout(() => { // Avoid double send (Autocomplete + server parse)
        if(!this.autocompleteData){ // Send to api server

          this.parseCatalogAddressAction({address: address})
              .then(() => {
                this.$root.$emit('closeFilterAddress');
                this.locationLoading = false;
              })
              .catch((error) => {
                this.error = error;
                this.locationLoading = false;
              });
        }
      }, 500);
    },
    currentPosition(){
      this.locationLoading = false;
      this.error = false;
      this.getUserCurrentPositionAction({reload:false})
          .then((address) => {
            document.getElementById('filterModalAutoComplete').value = address;
            this.locationGeocodeSuccess = true;
          })
          .catch((error_code) => {
            if(this.$te(error_code)){
              this.locationGeocodeError = this.$t(error_code);
            }
          });
    },
    listen(){
      this.$root.$on('openFilters', () => {
        this.open();
      });
    },
    async open(){
      this.isOpen = true;
      this.error = false;
      this.locationGeocodeSuccess = false;
      document.getElementById('appPage').style.overflowY = 'hidden';
      document.getElementById('filterModalAutoComplete').value = this.catalogData.address;
      this.$nextTick(() => {
        this.scroll();
      })

      if(this.$route.hash !== '#filter'){
        this.$router.push('#filter');
      }
      this.selectedSubCategories = this.filter.subCategories;
      this.when = this.filter.when;
      this.type = this.filter.type;

      await this.getDateSlotAction();
      if(this.filter.whenDay){
        if(this.catalogDateSlots.find(ds => ds.value === this.filter.whenDay)){
          this.date = this.filter.whenDay;
        }
      }
      else{
        this.date = this.catalogDateSlots[0].value;
      }
      await this.getTimeSlotAction(this.date);


      if(this.filter.whenTimestamp){
        if(this.catalogTimeSlots.find(ds => ds.value == this.filter.whenTimestamp)){
          this.hours = parseInt(this.filter.whenTimestamp);
        }
      }
      else{
        this.hours = this.catalogTimeSlots[0].value;
      }


    },
    resetAddress(){
      document.getElementById('filterModalAutoComplete').value = '';
    },
    save(){
      this.errors = [];
      if(this.hours === null && this.when === 'schedule_for_later'){
        this.errors.push('hours');
        return;
      }

      // Save all filters
      const filter = this.filter;
      filter.type = this.type;
      filter.subCategories = this.selectedSubCategories;
      filter.when = this.when;
      filter.whenDay = this.date;
      filter.whenTimestamp = this.hours;
      this.setFiltersAction(filter);

      localStorage.setItem('catalog_scroll', 0);
      document.querySelector('.catalog').scrollTop = 0;

      this.$router.replace('');
    },
    close(){
      this.isOpen = false;
      document.getElementById('appPage').style.overflowY = 'auto';
    },
    scroll(){
      const e = this.$refs.filterModalContent;
      if(e.scrollTop + e.clientHeight >= e.scrollHeight){
        this.scrollShadow = false;
      }
      else{
        this.scrollShadow = true;
      }
    }
  },
  computed: {
    ...mapGetters(['filter', 'catalogData', 'catalogDateSlots', 'catalogTimeSlots', 'subCategories'])
  },
  watch: {
    $route (to, from){
      if(from.hash === '#filter' && to.hash === ''){
        this.close();
      }
    },
    date(d){
      this.hours = null;
      this.getTimeSlotAction(d);
    },
    when(){
      this.errors = [];
    }
  },
  mounted() {
    this.listen();
    this.$refs.filterModalContent.addEventListener('scroll', (e) => {
      this.scroll();
    });
  }
}
</script>
