<template>
  <div class="catalog-new-merchants">
    <div class="catalog-title">
      <span>Les Nouveautés</span>
    </div>
    <swiper class="catalog-new-merchant__gallery" :options="swiperOptions">
      <swiper-slide class="item" v-for="merchant in newMerchants">
        <a :href="'/' + merchant.slug" class="item__merchant" :style="{'background-image': 'url('+merchant.image.path+')'}">
          <div class="merchant__info">
            <strong>{{merchant.name}}</strong>
            <span>{{merchant.city}} · {{merchant.category}}</span>
          </div>
        </a>
      </swiper-slide>
    </swiper>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  data() {
    const swiperOptions = {
      spaceBetween: 20,
      width: 260,
    }
    return {swiperOptions}
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['newMerchants'])
  }
}
</script>
