<template>
  <div>
    <div class="catalog-footer">
      <div class="baseline">
        <span>Delicity est la première plateforme de livraison équitable</span>
        <small>Notre mission est de construire une plateforme de livraison équitable, pour permettre aux restaurateurs de livrer leurs clients sans payer de commissions exorbitantes, aux livreurs d’être mieux rémunérés et aux gourmands de profiter d’un service de qualité au meilleur prix.</small>
      </div>

      <div class="footer-value">
        <div class="value__icon">
          <img src="https://delicity.b-cdn.net/public/assets/catalog/cooker.png" alt="">
        </div>
        <div class="value__text">
          Les restaurateurs ne payent pas de commission
        </div>
      </div>
      <div class="footer-value">
        <div class="value__icon">
          <img src="https://delicity.b-cdn.net/public/assets/catalog/biker.png" alt="">
        </div>
        <div class="value__text">
          Les livreurs sont rémunérés de manière plus juste
        </div>
      </div>
      <div class="footer-value">
        <div class="value__icon value__icon-double">
          <img src="https://delicity.b-cdn.net/public/assets/catalog/dancer1.png" alt="">
          <img src="https://delicity.b-cdn.net/public/assets/catalog/dancer2.png" alt="">
        </div>
        <div class="value__text">
          L’addition est souvent moins salée pour les gourmands
        </div>
      </div>

      <div class="footer-social">
        <a href="https://www.instagram.com/delicity.co/" target="_blank" class="social-item">
          <img src="../../assets/instagram.svg" alt="">
        </a>
        <a href="https://www.facebook.com/Delicity.co" target="_blank" class="social-item">
          <img src="../../assets/facebook.svg" alt="">
        </a>
        <a href="http://m.me/delicity.co" target="_blank" class="social-item">
          <img src="../../assets/messenger.svg" alt="">
        </a>
      </div>

      <div class="text-center d-flex flex-column mt-4">
        <a href="https://merchant.delicity.com/" class="text-white mb-1" target="_blank">Connexion Pro</a>
        <a href="https://t.delicity.co/11KLCG" class="text-white mb-1" target="_blank">On recrute !</a>
        <a href="https://pro.delicity.com/confidentialite/"  class="text-white mb-1" target="_blank">Politique de confidentialité</a>
        <a href="https://pro.delicity.com/cgv/" class="text-white mb-1" target="_blank">Conditions générales de vente</a>
      </div>

    </div>
    <div class="catalog-footer-copy">
      <a href="https://delicity.com"><img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg"></a>
      <span class="copy">&copy; 2020-{{ currentYear }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters([]),
    currentYear(){
      return new Date().getFullYear();
    }
  }
}
</script>
