<template>
  <div class="catalog-home-category">
    <div class="catalog-title">
      <span>Les catégories</span>
    </div>
    <small class="catalog-home-category__error" v-if="error">{{error}}</small>
    <div class="catalog-home-category__filters">
      <button @click="selectCategory(sb.id)" v-for="sb in subCategories">{{sb.name}}</button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      error: false
    }
  },
  methods: {
    ...mapActions(['setSubCategoriesAction', 'getUserCurrentPositionAction']),
    selectCategory(id){
      this.setSubCategoriesAction([id]);
      this.getUserCurrentPositionAction({reload: true}).then(() => {})
          .catch((e) => {
            this.error = this.$t(e) + '... ' + this.$t('enter_your_address_for_see_merchants');
          });
    }
  },
  computed: {
    ...mapGetters(['subCategories'])
  }
}
</script>
