<template>
  <div class="side-nav-container" :class="{'side-nav-open': isOpen}">
    <div class="side-nav">

      <div class="close-container">
        <button @click="close"><i class="material-icons notranslate">close</i></button>
      </div>

      <div class="brand">
        <img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg" alt="delicity">
      </div>

      <div class="links">
        <a target="_blank" href="/smile">Comment ça marche ?</a>
        <a target="_blank" href="https://merchant.delicity.com">Espace professionnels</a>
        <a target="_blank" href="https://pro.delicity.co/livreurs/">Espace livreurs</a>
        <a target="_blank" href="https://www.instagram.com/delicity.co/">Instagram</a>
        <a target="_blank" href="https://www.facebook.com/Delicity.co">Facebook</a>
        <a target="_blank" href="https://pro.delicity.co/#formulaire">Contact</a>
      </div>
    </div>
    <div class="outside" @click="close"></div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    ...mapActions([]),
    listen(){
      this.$root.$on('openSideNav', () => {
        this.isOpen = true;
        document.getElementById('appPage').style.overflowY = 'hidden';
      });
    },
    close(){
      this.isOpen = false;
      document.getElementById('appPage').style.overflowY = 'auto';
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    this.listen();
  }
}
</script>
