<template>

  <a :href="'/' + merchant.slug + '?source=marketplace'" class="merchant-list__item" :class="{'merchant-list__item-unavailable': !merchant.available}">
    <div class="item__image" :style="{'background-image': 'url('+merchant.images[0]+')'}">

      <div class="image__discount" v-if="merchant.discount && merchant.available">
        <div class="discount__box" v-if="merchant.discount.type === 'free_delivery' && merchant.discount.minValue.value !== 0">
          <i class="material-icons notranslate">local_offer</i>
          {{ $t('free_delivery_from', {price: merchant.discount.minValue.price}) }}
        </div>
        <div class="discount__box" v-else-if="merchant.discount.type === 'free_delivery' && merchant.discount.minValue.value === 0">
          <i class="material-icons notranslate">local_offer</i>
          {{ $t('free_delivery') }}
        </div>
        <div class="discount__box" v-else>
          <i class="material-icons notranslate">local_offer</i>
          Promotion
        </div>
      </div>

      <div class="image__unavailable" v-if="!merchant.available">
        <div class="next_open"><i class="material-icons notranslate">history</i><span>Ouvre {{merchant.nextOpen}}</span></div>
      </div>
    </div>
    <div class="item__details">
      <div class="details__head">
        <span class="title">{{merchant.name}}</span>
        <span class="duration">
          <i class="material-icons notranslate">schedule</i>
          {{merchant.duration}}
        </span>
      </div>
      <div class="details__meta">
        <small>{{merchant.meta}}</small>
      </div>
    </div>
  </a>


</template>


<script>
import {mapGetters} from "vuex";

export default {
  name: 'MerchantItem',
  props: ['merchant'],
  computed: {
    ...mapGetters([]),
  }
}
</script>
