<template>
  <div class="catalog-filter" id="catalogFilter"  @click="openFilter">
    <div class="filter__bar"></div>
    <div class="details">
      <div class="details__head">
        <span>{{$t(filter.type)}}</span> ·
        <template v-if="filter.when === 'now'">Maintenant</template>
        <template v-if="filter.when === 'schedule_for_later'">{{scheduled}}</template>
      </div>
      <div class="details__footer" v-if="catalogData && catalogData.addressShort">{{catalogData.addressShort}}</div>
    </div>
    <div class="filter-button">
      <span>modifier</span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from "moment";
export default {
  data() {
    return {
      open: false
    }
  },
  methods: {
    ...mapActions([]),
    openFilter(){
      this.$root.$emit('openFilters')
    }
  },
  computed: {
    ...mapGetters(['filter', 'catalogData']),
    scheduled(){
      let string = moment.unix(this.filter.whenTimestamp).locale("fr").format('ddd D à HH:mm');
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
</script>
